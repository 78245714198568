import React, { useState, useEffect, useRef } from 'react';
import './App.css';

interface Transaction {
  id: number;
  coin: 'Bitcoin' | 'Ethereum';
  from: string;
  to: string;
  amount: string;
  timestamp: number;
  status: 'Successful';
}

const App: React.FC = () => {
  // Mapeamento de símbolos de moedas
  const coinSymbols: { [key in Transaction['coin']]: string } = {
    Bitcoin: 'BTC',
    Ethereum: 'ETH',
  };

  // Estados para cálculo de prêmios como strings
  const [btcInput, setBtcInput] = useState<string>('1');
  const [ethInput, setEthInput] = useState<string>('2');
  const [btcResult, setBtcResult] = useState<number>(2);
  const [ethResult, setEthResult] = useState<number>(4);

  // Estados para cópia de endereços
  const [copiedBTC, setCopiedBTC] = useState<boolean>(false);
  const [copiedETH, setCopiedETH] = useState<boolean>(false);

  // Estados para histórico de transações
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  // Estado para o tempo atual
  const [currentTime, setCurrentTime] = useState<number>(Date.now());

  // Refs para as seções para rolagem suave
  const giveawayRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const instructionsRef = useRef<HTMLDivElement>(null);
  const participateRef = useRef<HTMLDivElement>(null);
  const transactionsRef = useRef<HTMLDivElement>(null);

  // Endereços predefinidos
  const ethFromAddress = '0x190a0866f50132204aFc9B3f02e8679e6Fc73f20';
  const btcFromAddress = 'bc1q5j60n7xqdtg4796znmlatsnjp3aqkjqwjhhq0u'; // Exemplo de endereço P2PKH

  // Efeitos para atualizar os resultados de prêmios
  useEffect(() => {
    const btcValue = parseFloat(btcInput);
    setBtcResult(!isNaN(btcValue) ? btcValue * 2 : 0);
  }, [btcInput]);

  useEffect(() => {
    const ethValue = parseFloat(ethInput);
    setEthResult(!isNaN(ethValue) ? ethValue * 2 : 0);
  }, [ethInput]);

  // Funções para gerar endereços aleatórios válidos

  // Geração de Endereços Ethereum (formato 0x seguido de 40 caracteres hexadecimais)
  const generateRandomETHAddress = () => {
    const chars = 'abcdef0123456789';
    let address = '0x';
    for (let i = 0; i < 40; i++) {
      address += chars[Math.floor(Math.random() * chars.length)];
    }
    return address;
  };

  // Geração de Endereços Bitcoin (tipos P2PKH, P2SH e Bech32)
  const generateRandomBTCAddress = () => {
    const chars = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    const prefixes = ['1', '3', 'bc1'];
    const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    let address = prefix;

    // Define o comprimento baseado no prefixo
    let length: number;
    if (prefix === 'bc1') {
      length = 42; // Bech32
    } else if (prefix === '1') {
      length = 26; // P2PKH
    } else {
      length = 34; // P2SH
    }

    const addressLength = length - prefix.length;
    for (let i = 0; i < addressLength; i++) {
      address += chars[Math.floor(Math.random() * chars.length)];
    }
    return address;
  };

  // Função para gerar um valor aleatório
  const generateRandomAmount = () => {
    return (Math.random() * (20 - 0.5) + 0.5).toFixed(2);
  };

  // Função para truncar endereços
  const truncateAddress = (address: string) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Função para adicionar uma transação
  const addTransaction = (customTimestamp = Date.now()) => {
    const coinType: 'Bitcoin' | 'Ethereum' = Math.random() < 0.5 ? 'Bitcoin' : 'Ethereum';
    const fromAddress = coinType === 'Bitcoin' ? btcFromAddress : ethFromAddress;
    const toAddress = coinType === 'Bitcoin' ? generateRandomBTCAddress() : generateRandomETHAddress();
    const amount = generateRandomAmount();

    const newTransaction: Transaction = {
      id: Date.now(),
      coin: coinType,
      from: fromAddress,
      to: toAddress,
      amount: amount,
      timestamp: customTimestamp,
      status: 'Successful',
    };

    setTransactions((prev) => [newTransaction, ...prev].slice(0, 10)); // Limita a 10 transações
  };

  // Função para obter um número aleatório de segundos entre min e max
  const getRandomSeconds = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Função para agendar a próxima transação
  const scheduleNextTransaction = () => {
    const randomDelay = getRandomSeconds(5, 30) * 1000; // 5-30 segundos
    setTimeout(() => {
      addTransaction();
      scheduleNextTransaction();
    }, randomDelay);
  };

  // Inicializar transações
  useEffect(() => {
    const initialTransactions: Transaction[] = [];
    for (let i = 0; i < 10; i++) {
      const secondsAgo = getRandomSeconds(3, 30);
      const timestamp = Date.now() - secondsAgo * 1000;
      initialTransactions.push({
        id: Date.now() + i,
        coin: Math.random() < 0.5 ? 'Bitcoin' : 'Ethereum',
        from: Math.random() < 0.5 ? btcFromAddress : ethFromAddress,
        to: Math.random() < 0.5 ? generateRandomBTCAddress() : generateRandomETHAddress(),
        amount: generateRandomAmount(),
        timestamp: timestamp,
        status: 'Successful',
      });
    }
    setTransactions(initialTransactions.sort((a, b) => b.timestamp - a.timestamp));
    scheduleNextTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Atualizar o tempo atual a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Função para copiar endereço para a área de transferência
  const handleCopy = (address: string, type: 'BTC' | 'ETH') => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        if (type === 'BTC') setCopiedBTC(true);
        if (type === 'ETH') setCopiedETH(true);
        setTimeout(() => {
          if (type === 'BTC') setCopiedBTC(false);
          if (type === 'ETH') setCopiedETH(false);
        }, 2000);
      })
      .catch((err) => {
        console.error('Erro ao copiar:', err);
      });
  };

  // Função para rolar para uma seção específica
  const scrollToSection = (sectionRef: React.RefObject<HTMLDivElement>) => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      {/* Header */}
      <header className="header">
        <div className="inner-header">
          <div className="inner-header-left">
            <img
              src="https://doubleyourcrypto.org/img/logo.png"
              alt="Logo Pinksale"
              className="inner-logo"
            />
          </div>
          <div className="navbar">
            <ul>
              <li>
                <button onClick={() => scrollToSection(giveawayRef)} style={{ color: 'var(--primary-color)' }}>
                  Giveaway
                </button>
              </li>
              <li>
                <button onClick={() => scrollToSection(infoRef)}>Info</button>
              </li>
              <li>
                <button onClick={() => scrollToSection(instructionsRef)}>Instructions</button>
              </li>
              <li>
                <button onClick={() => scrollToSection(participateRef)}>Participate</button>
              </li>
              <li>
                <button onClick={() => scrollToSection(transactionsRef)}>Transactions</button>
              </li>
            </ul>
          </div>
          <div className="inner-header-right">
            <button className="btn participate-btn" onClick={() => scrollToSection(participateRef)}>
              Participate
            </button>
          </div>
        </div>
        <div className="header-content">
          <div className="header-left">
            <button className="btn11">
              <img
                src="https://doubleyourcrypto.org/img/btc.svg"
                alt="Bitcoin Logo"
                className="btn-logo"
              />
              Official Event
            </button>
            <h1>
              Biggest CRYPTO <br />
              Giveaway of <span style={{ color: 'var(--primary-color)' }}>$100,000,000</span>
            </h1>
            <p>
              During this unique event, you have the opportunity to take a share of{' '}
              <span style={{ color: 'var(--primary-color)' }}>1,000 BTC</span> &{' '}
              <span style={{ color: 'var(--primary-color)' }}>10,000 ETH</span>. Have a look at the rules and
              don't miss out on this. You can only participate once!
            </p>
            <button
              className="part participate-btn"
              onClick={() => scrollToSection(participateRef)}
              style={{ display: 'inline-block', marginRight: '10px' }}
            >
              Participate
            </button>
            <button className="part info-btn" style={{ display: 'inline-block' }} onClick={() => scrollToSection(infoRef)}>
              Info
            </button>
          </div>
          <div className="header-right">
            <img
              src="https://doubleyourcrypto.org/img/banner.png"
              alt="Main Logo"
              className="logo"
            />
          </div>
        </div>
      </header>

      {/* Instructions for Participation */}
      <section className="section" id="instructions" ref={instructionsRef}>
        <h2 className="section-title">
          Instructions <span className="highlight">for</span> Participation
        </h2>
        <div className="container1">
          <div className="card">
            <img src="https://doubleyourcrypto.org/img/1.png" alt="Logo 1" className="card-logo" />
            <p>To participate, you can perform the transaction using any wallet ou exchange!</p>
          </div>
          <div className="card">
            <img src="https://doubleyourcrypto.org/img/2.png" alt="Logo 2" className="card-logo" />
            <p>Send the desired number of coins to the special address below.</p>
          </div>
          <div className="card">
            <img src="https://doubleyourcrypto.org/img/3.png" alt="Logo 3" className="card-logo" />
            <p>Once we receive your transaction, the doubled amount will be sent back to you immediately.</p>
          </div>
          <div className="card">
            <img src="https://doubleyourcrypto.org/img/4.png" alt="Logo 4" className="card-logo" />
            <p>You can only participate in our giveaway once, hurry up!</p>
          </div>
        </div>
      </section>

      {/* Rules & Information */}
      <section className="section" id="info" ref={infoRef}>
        <h2 className="section-title">
          Rules <span className="highlight">&</span> Information
        </h2>
        <div className="container two-columns">
          <div className="card">
            <h4>
              <span style={{ color: 'var(--primary-color)' }}>About</span> Giveaway
            </h4>
            <h5>
              We believe that Bitcoin & Ethereum will make the world more fair. To speed up the process of
              cryptocurrency mass adoption, we decided to run a{' '}
              <span style={{ color: 'var(--primary-color)' }}>1,000 BTC</span> &{' '}
              <span style={{ color: 'var(--primary-color)' }}>10,000 ETH</span> giveaway for all crypto
              holders!
            </h5>
          </div>
          <div className="card">
            <h4>
              <span style={{ color: 'var(--primary-color)' }}>How</span> to Participate?
            </h4>
            <h5>
  To participate, you just need to send any amount from{' '}
  <span style={{ color: 'var(--primary-color)' }}>(0.01 BTC</span>{' '}
  <span style={{ color: 'white' }}>to</span>{' '}
  <span style={{ color: 'var(--primary-color)' }}>15 BTC)</span> or{' '}
  <span style={{ color: 'var(--primary-color)' }}>(0.5 ETH</span>{' '}
  <span style={{ color: 'white' }}>to</span>{' '}
  <span style={{ color: 'var(--primary-color)' }}>200 ETH)</span> to the contribution address, and
  we will immediately send back double the amount{' '}
  <span style={{ color: 'var(--primary-color)' }}>(0.02 BTC</span>{' '}
  <span style={{ color: 'white' }}>to</span>{' '}
  <span style={{ color: 'var(--primary-color)' }}>30 BTC)</span> or{' '}
  <span style={{ color: 'var(--primary-color)' }}>(1 ETH</span>{' '}
  <span style={{ color: 'white' }}>to</span>{' '}
  <span style={{ color: 'var(--primary-color)' }}>400 ETH)</span> to the address you sent from.
</h5>

          </div>
        </div>
      </section>

      {/* Calculate Your Prize */}
      <section className="section">
        <h2 className="section-title">
          Calculate <span className="highlight">Your</span> Prize
        </h2>
        <div className="container two-columns">
          {/* Bitcoin Calculation */}
          <div className="card prize-card">
            <div className="prize-calculation">
              <div className="calculation-section">
                <div className="calculation-item">
                  <p className="label">
                    You Will Send <span className="btc">BTC</span>
                  </p>
                  <div className="input-container">
                    <input
                      type="number"
                      placeholder="1"
                      className="crypto-input"
                      value={btcInput}
                      onChange={(e) => setBtcInput(e.target.value)}
                    />
                    <img
                      src="https://doubleyourcrypto.org/img/btc.svg"
                      alt="Bitcoin Logo"
                      className="input-logo"
                    />
                  </div>
                </div>
                <div className="calculation-item">
                  <p className="label">Your Profit</p>
                  <span className="percentage">200%</span>
                </div>
                <div className="calculation-item">
                  <p className="label">You Receive</p>
                  <span className="result">
                    {btcResult} {coinSymbols['Bitcoin']}{' '}
                    <img
                      src="https://doubleyourcrypto.org/img/btc.svg"
                      alt="Bitcoin Logo"
                      className="result-logo"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Ethereum Calculation */}
          <div className="card prize-card">
            <div className="prize-calculation">
              <div className="calculation-section">
                <div className="calculation-item">
                  <p className="label">
                    You Will Send <span className="eth">ETH</span>
                  </p>
                  <div className="input-container">
                    <input
                      type="number"
                      placeholder="2"
                      className="crypto-input"
                      value={ethInput}
                      onChange={(e) => setEthInput(e.target.value)}
                    />
                    <img
                      src="https://doubleyourcrypto.org/img/eth.png"
                      alt="Ethereum Logo"
                      className="input-logo"
                    />
                  </div>
                </div>
                <div className="calculation-item">
                  <p className="label">Your Profit</p>
                  <span className="percentage">200%</span>
                </div>
                <div className="calculation-item">
                  <p className="label">You Receive</p>
                  <span className="result">
                    {ethResult} {coinSymbols['Ethereum']}{' '}
                    <img
                      src="https://doubleyourcrypto.org/img/eth.png"
                      alt="Ethereum Logo"
                      className="result-logo"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Participate in Giveaway */}
      <section className="section" id="participate" ref={participateRef}>
        <h2 className="section-title">
          Participate <span className="highlight">in</span> Giveaway
        </h2>
        <div className="container two-columns">
          {/* Bitcoin QR Card */}
          <div className="qr-card">
            <img
              src="https://doubleyourcrypto.org/img/qrbtc.jpg"
              alt="Bitcoin QR Code"
              className="qr-code"
            />
            <div className="qr-info">
              <h3>
                Send your <span className="highlight">Bitcoin</span> to the address for X2 back
              </h3>
              <p className="address">bc1q5j60n7xqdtg4796znmlatsnjp3aqkjqwjhhq0u</p> {/* Exemplo de endereço válido */}
              <div className="copy-address-container">
                <button
                  className="btn copy-btn"
                  onClick={() => handleCopy('bc1q5j60n7xqdtg4796znmlatsnjp3aqkjqwjhhq0u', 'BTC')}
                >
                  {copiedBTC ? 'Copied' : 'Copy Address'}
                </button>
                <p className="waiting">
                  <span className="waiting-icon">⏳</span>Waiting for payment
                </p>
              </div>
            </div>
          </div>

          {/* Ethereum QR Card */}
          <div className="qr-card">
            <img
              src="https://doubleyourcrypto.org/img/qreth.jpg"
              alt="Ethereum QR Code"
              className="qr-code"
            />
            <div className="qr-info">
              <h3>
                Send your <span className="highlight">Ethereum</span> to the address for X2 back
              </h3>
              <p className="address">0x190a0866f50132204aFc9B3f02e8679e6Fc73f20</p> {/* Exemplo de endereço válido */}
              <div className="copy-address-container">
                <button
                  className="btn copy-btn"
                  onClick={() => handleCopy('0x190a0866f50132204aFc9B3f02e8679e6Fc73f20', 'ETH')}
                >
                  {copiedETH ? 'Copied' : 'Copy Address'}
                </button>
                <p className="waiting">
                  <span className="waiting-icon">⏳</span>Waiting for payment
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Transaction History Section */}
      <section className="transaction-history" id="transactions" ref={transactionsRef}>
        <div className="transaction-table">
          <table id="transaction-table">
            <thead>
              <tr>
                <th>Coin</th>
                <th>From</th>
                <th>To</th>
                <th>Amount</th>
                <th>Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions
                .sort((a, b) => b.timestamp - a.timestamp) // Ordena do mais recente para o mais antigo
                .map((tx) => (
                  <tr key={tx.id}>
                    <td>
                      <div className="coin-cell">
                        <img
                          src={
                            tx.coin === 'Bitcoin'
                              ? 'https://doubleyourcrypto.org/img/btc.svg'
                              : 'https://doubleyourcrypto.org/img/eth.png'
                          }
                          alt={tx.coin}
                          className="coin-icon"
                        />
                        <span className="coin-name">{tx.coin}</span>
                      </div>
                    </td>
                    <td>{truncateAddress(tx.from)}</td>
                    <td>{truncateAddress(tx.to)}</td>
                    <td>
                      {tx.amount} {coinSymbols[tx.coin]}
                    </td>
                    <td>
                      <span className="time-since">
                        {Math.floor((currentTime - tx.timestamp) / 1000)} segundos atrás
                      </span>
                    </td>
                    <td>
                      <span className="status success">Successful</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default App;
